import type { Dobby, ModelConstructor } from "@moirei/dobby";
import { isNil, pick } from "lodash";
import { createHooks } from "hookable";
import type { RouteLocationRaw } from "vue-router";
import { fillModel } from "../helpers/fill-model";

interface SaveContextBase<T> {
  defaults?: T;
  saveText?: string;
  unsavedText?: string;
  discardConfirmTitle?: string;
  discardConfirmMessage?: string;
  persistent?: boolean;
  exitOnDiscard?: boolean;
  refetchOnSave?: boolean;
}

interface SaveInput<T> {
  data: T;
  original: T;
  changes: Partial<T>;
}

interface SaveContextInput<T> extends SaveContextBase<T> {
  fetch?: { (): Promise<T> };
  save: { (input: SaveInput<T>): Promise<T | void> };
}

interface SaveContextQueryInput<T extends Dobby>
  extends SaveContextBase<DobbyAttributes<T>> {
  query: () => Promise<any>;
}

const isQueryInput = (config?: any): config is SaveContextQueryInput<Dobby> =>
  !!config?.query;

const events = createHooks();

const useSaveContextData = <T extends Dobby>() => {
  return useDataBucketStore<T>({ id: "save-context" });
};

const useSaveContextStore = <T extends Dobby>() => {
  const dataBucket = useSaveContextData<T>();

  const initialState = {
    saveText: "Save",
    unsavedText: "Unsaved changes",
    discardConfirmTitle: "Discard all unsaved changes",
    discardConfirmMessage:
      "If you discard changes, you’ll delete any edits you made since you last saved.",
    persistent: false,
    exitOnDiscard: false,
    refetchOnSave: false,
    fetching: false,
    loaded: false,
    refetching: false,
    saving: false,
    disabledRec: {},
    model: undefined,
  };

  const s = defineStore("save-context", () => {
    const saveText = ref("");
    const unsavedText = ref("");
    const discardConfirmTitle = ref("");
    const discardConfirmMessage = ref("");
    const persistent = ref(false);
    const exitOnDiscard = ref(false);
    const refetchOnSave = ref(false);
    const fetching = ref(false);
    const loaded = ref(false);
    const refetching = ref(false);
    const saving = ref(false);
    const disabledRec = ref<Record<string, boolean>>({});
    const model = shallowRef<T>();

    const setInitialState = () => {
      saveText.value = initialState.saveText;
      unsavedText.value = initialState.unsavedText;
      discardConfirmTitle.value = initialState.discardConfirmTitle;
      discardConfirmMessage.value = initialState.discardConfirmMessage;
      persistent.value = initialState.persistent;
      exitOnDiscard.value = initialState.exitOnDiscard;
      refetchOnSave.value = initialState.refetchOnSave;
      fetching.value = initialState.fetching;
      loaded.value = initialState.loaded;
      refetching.value = initialState.refetching;
      saving.value = initialState.saving;
      disabledRec.value = initialState.disabledRec;
      model.value = initialState.model;
    };

    const uploads = useMediaUpload({
      id: "save-context",
      model: model as Ref<any>,
    });

    // validation
    const validSource = useFormValid();
    const getValidSource = () => validSource;
    const createValidProxy = validSource.createProxy.bind(validSource);
    const getValid = validSource.getValidRef.bind(validSource);

    const loading = computed(
      () => fetching.value || refetching.value || saving.value
    );

    const disabled = computed(() => someTrue(Object.values(disabledRec.value)));
    const canEdit = computed(() => !disabled.value && !loading.value);

    const canSave = computed(
      () =>
        !disabled.value &&
        !loading.value &&
        dataBucket.isDirty &&
        validSource.isValid.value
    );

    const canDiscard = computed(
      () =>
        !disabled.value &&
        !loading.value &&
        (exitOnDiscard.value || dataBucket.isDirty)
    );

    const useDisabled = () => {
      const id = uuid();
      return computed({
        set(v: boolean) {
          disabledRec.value[id] = v;
        },
        get() {
          return !!disabledRec.value[id];
        },
      });
    };

    const setModel = (m: T) => (model.value = m);
    const getModel = (): T | undefined => model.value;

    const $reset = () => {
      dataBucket.$reset();
      setInitialState();
      // TODO: reset valid
    };

    setInitialState();

    return {
      saveText,
      unsavedText,
      discardConfirmTitle,
      discardConfirmMessage,
      persistent,
      exitOnDiscard,
      refetchOnSave,
      canEdit,
      canSave,
      canDiscard,
      fetching,
      loaded,
      refetching,
      saving,
      loading,
      model,
      setModel,
      getModel,
      getValidSource,
      getValid,
      createValidProxy,
      useValid: validSource.useValid,
      useDisabled,
      mediaField: uploads.mediaField,
      $reset,
    };
  });

  return s();
};

const useSaveContextBaseImpl = <T extends Dobby>() => {
  const store = useSaveContextStore<T>();
  const dataBucket = useSaveContextData<T>();
  const { checkConfirm } = useConfirm();

  const save = async () => events.callHook("save");
  const refetch = async () => events.callHook("refetch");
  const onSaved = (fn: { (...a: any[]): any }) => {
    events.hook("saved", fn);
  };
  const onResult = (fn: { (data: any): void | any }) => {
    events.hook("result", fn);
  };
  const onBeforeSave = (fn: { (input: SaveInput<any>): void | any }) => {
    events.hook("beforeSave", fn);
  };
  const onDiscarded = (fn: { (): void | any }) => {
    events.hook("discarded", fn);
  };

  const discard = async () => {
    const ok = dataBucket.isDirty
      ? await checkConfirm({
          title: store.discardConfirmTitle,
          message: store.discardConfirmMessage,
          doneText: "Discard",
          cancelText: "Continue editing",
        })
      : true;

    if (ok) {
      dataBucket.discardChanges();
      await events.callHook("discarded");
      if (store.exitOnDiscard) {
        const router = useRouter();
        router.go(-1);
      }
    }
  };

  /**
   * Wrap the function around a function that disables
   * save context state.
   */
  const wrapFn = <A extends any[], R>(fn: { (...a: A): Promise<R> | R }) => {
    const disabled = store.useDisabled();
    return async (...a: A): Promise<R | void> => {
      if (store.canEdit) {
        disabled.value = true;
        const p = Promise.resolve(fn(...a));
        return p.finally(() => {
          disabled.value = false;
        });
      }
    };
  };

  const reset = () => {
    store.$reset();
    dataBucket.$reset();
  };

  return {
    ...storeToRefs(store),
    ...storeToRefs(dataBucket),
    ...pick(store, [
      "getValidSource",
      "getValid",
      "createValidProxy",
      "useValid",
      "useDisabled",
      "mediaField",
    ]),
    ...pick(dataBucket, ["fill"]),
    discard,
    save,
    refetch,
    reset,
    wrapFn,
    onSaved,
    onResult,
    onBeforeSave,
    onDiscarded,
  };
};

const useSaveContextMainImpl = <T extends Dobby>(
  config: SaveContextInput<T>
) => {
  const base = useSaveContextBaseImpl<T>();
  const dataBucket = useSaveContextData();
  const { checkConfirm } = useConfirm();

  base.reset();

  if (!isNil(config.saveText)) {
    base.saveText.value = config.saveText;
  }
  if (!isNil(config.unsavedText)) {
    base.unsavedText.value = config.unsavedText;
  }
  if (!isNil(config.discardConfirmTitle)) {
    base.discardConfirmTitle.value = config.discardConfirmTitle;
  }
  if (!isNil(config.discardConfirmMessage)) {
    base.discardConfirmMessage.value = config.discardConfirmMessage;
  }
  if (!isNil(config.persistent)) {
    base.persistent.value = config.persistent;
  }
  if (!isNil(config.exitOnDiscard)) {
    base.exitOnDiscard.value = config.exitOnDiscard;
  }
  if (!isNil(config.refetchOnSave)) {
    base.refetchOnSave.value = config.refetchOnSave;
  }
  if (!isNil(config.defaults)) {
    dataBucket.fillOriginal(config.defaults as any);
  }

  const addHook = (name: string, handler: any) => {
    const register = once(() => {
      const unsub = events.hook(name, handler);
      onUnmounted(unsub);
    });
    onMounted(register);
  };

  const updateDate = (data: any) => {
    dataBucket.fillOriginal(data);
  };

  const handleFetchError = () => {
    //
  };

  if (config.fetch) {
    const fetchOnce = once(() => {
      base.fetching.value = true;
      config.fetch!()
        .then(updateDate)
        .catch(handleFetchError)
        .finally(() => {
          base.fetching.value = false;
          base.loaded.value = true;
        });
    });

    onMounted(() => nextTick(fetchOnce));

    addHook("refetch", () => {
      base.refetching.value = true;
      config.fetch!()
        .then(updateDate)
        .catch(handleFetchError)
        .finally(() => {
          base.refetching.value = false;
        });
    });
  }

  addHook("save", () => {
    base.saving.value = true;
    const original: any = Object.assign({}, toRaw(dataBucket.original));
    const changes: any = Object.assign({}, toRaw(dataBucket.changes));
    const data: any = Object.assign({}, toRaw(dataBucket.data));
    config
      .save({ data, original, changes })
      .then((result) => {
        dataBucket.keepChanges();
        // base.reset();
        if (base.refetchOnSave) {
          events.callHook("refetch");
        }
        events.callHook("saved", result);
      })
      .finally(() => {
        base.saving.value = false;
      });
  });

  onBeforeRouteLeave(() => {
    if (base.isDirty.value) {
      return checkConfirm({
        title: "Leave page with unsaved changes?",
        message: "Leaving this page will delete all unsaved changes.",
        doneText: "Leave page",
        cancelText: "Stay",
      });
    }
  });

  return base;
};

type SaveContextMain<T extends Dobby> = ReturnType<
  typeof useSaveContextMainImpl<T>
>;
type SaveContext<T extends Dobby> = ReturnType<
  typeof useSaveContextBaseImpl<T>
>;

function useSaveContext<T extends Dobby>(
  config: SaveContextInput<T>
): SaveContextMain<T>;
function useSaveContext<T extends Dobby>(
  config: SaveContextQueryInput<T>
): SaveContextMain<T>;
function useSaveContext<T extends Dobby>(): SaveContext<T>;
function useSaveContext<T>(
  config?: SaveContextInput<any> | SaveContextQueryInput<any>
): SaveContextMain<any> | SaveContext<any> {
  if (!config) {
    return useSaveContextBaseImpl<any>();
  }

  if (isQueryInput(config)) {
    const { query, ...conf } = config;
    const store = useSaveContextStore<any>();

    const fetch = async () => {
      const model = await query();
      store.setModel(model);
      const data = model?.$toJson() || {};
      return (await events.callHook("result", data)) || data;
    };

    const save = async (input: SaveInput<any>) => {
      const m = store.getModel();
      if (m) {
        const x = (await events.callHook("beforeSave", input)) || input;
        fillModel(x, m);
        return m.$save();
      }
    };

    // resets store incl. fetched model
    return useSaveContextMainImpl<any>({
      ...conf,
      fetch,
      save,
    });
  }

  return useSaveContextMainImpl(config as SaveContextInput<any>);
}

const useSaveContextDeleteModel = <T extends Dobby>(options: {
  model: ModelConstructor<T>;
  routeOnDelete: RouteLocationRaw;
  itemId?: string;
  itemName?: string;
  message?: string;
}) => {
  const { model, routeOnDelete, ...opts } = options;
  const { wrapFn } = useSaveContext<T>();
  const dataBucket = useSaveContextData();
  const compose = useDeleteModel(model, opts);

  const del = wrapFn(() => {
    return compose.del(dataBucket.original).then((ok) => {
      if (ok !== false) {
        navigateTo(routeOnDelete);
      }
    });
  });

  return {
    ...compose,
    del,
  };
};

export { useSaveContext, useSaveContextDeleteModel };
