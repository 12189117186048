import type { Dobby, Enumerable } from "@moirei/dobby";
import { Media, type MediaFile, type UploadFile } from "../models";
import type { MediaFile as IMediaFile } from "../modules/media-library/runtime/types";
import { cloneDeep, isString } from "lodash";
import type { ShallowRef } from "vue";
import {
  getMediaFileId,
  setMediaFileId,
  setMediaFileUpload,
} from "../private/media-input/lib";
import { useCropper } from "../private/media-input/cropper";

type FieldOptions = Partial<{
  multiple: boolean;
  accept: string;
  maxSize: number;
  aspectRatio: number;
}>;
type MediaLike = string | Media | MediaFile | IMediaFile;
type UploadDataBucket = Record<string, Enumerable<MediaLike | undefined>>;

export type MediaUploadField = {
  media: ComputedRef<IMediaFile | IMediaFile[] | undefined>;
  loading: Ref<boolean>;
  accept?: string;
  setUpload: (upload: UploadFile) => void;
  use: (media: Media | MediaFile) => void;
  setState: (media: MediaLike) => void;
  fromUrl: (url: string) => void;
  remove: (media: MediaLike) => void;
  edit: (media: IMediaFile) => void;
};

export const useMediaUpload = (o?: {
  id?: string;
  model?: ShallowRef<Dobby>;
}) => {
  const crop = useCropper();
  const { isDirty, data, setOriginal } = useDataBucket<UploadDataBucket>({
    id: o?.id,
  });

  // TODO:
  // keep changes

  const setMedia = (name: string, media: MediaLike) => {
    setOriginal(name, media);
  };

  const getModel = (name: string) => {
    if (o?.model) {
      const field = o.model.value[name];
      if (field instanceof Media) {
        return field;
      }
    }
  };

  const mediaField = (name: string, o?: FieldOptions): MediaUploadField => {
    const loading = ref(false);

    const media = computed((): IMediaFile | IMediaFile[] | undefined => {
      const m = data.value[name];

      const f = (v?: MediaLike) => {
        if (isString(v)) {
          return { id: v, name: v };
        }
        return v;
      };

      if (Array.isArray(m)) {
        // @ts-ignore
        return m.filter((x) => !!x).map(f);
      }

      return f(m);
    });

    const _setUpload = async (upload: UploadFile, shouldCrop: boolean) => {
      const id = uuid();
      const m = getModel(name);

      let preview = await getFileSrc(upload, m);

      if (shouldCrop && o?.aspectRatio) {
        const u = await crop(preview, o.aspectRatio).catch(() => {
          // canceled/exited crop
        });
        if (u) {
          upload = u;
          preview = await getFileSrc(u, m);
        }
      }

      setMediaFileId(preview, id);
      setMediaFileUpload(preview, upload);

      const entry = markRaw(preview);

      if (o?.multiple) {
        const u = cloneDeep(data.value[name] || []) as MediaLike[];
        u.push(entry);
        data.value[name] = u;
      } else {
        data.value[name] = entry;
      }
    };

    const edit = async (media: IMediaFile) => {
      if (o?.aspectRatio) {
        const blob = await crop(media, o.aspectRatio).catch(() => {
          // canceled/exited crop
        });
        if (blob) {
          remove(media);
          _setUpload(blob, false);
        }
      }
    };

    const setUpload = async (upload: UploadFile) => {
      return _setUpload(upload, true);
    };

    const use = (media: Media | MediaFile) => {
      console.log("use", media);
    };

    const setState = (media: MediaLike) => {
      setMedia(name, media);
    };

    const fromUrl = async (url: string) => {
      const blob = await fetch(url).then((r) => r.blob());
      const file = blobToFile(blob, "Image file");
      setUpload(file);
    };

    const remove = (media: MediaLike) => {
      const id = isString(media) ? media : getMediaFileId(media);
      if (o?.multiple) {
        const entries = (data.value[name] || []) as MediaLike[];
        data.value[name] = entries.filter((entry) => {
          const entryId = isString(entry) ? entry : getMediaFileId(entry);
          return entryId !== id;
        });
      } else {
        data.value[name] = undefined;
      }
    };

    return {
      loading,
      media,
      setUpload,
      use,
      setState,
      fromUrl,
      remove,
      edit,
    };
  };

  return {
    isDirty,
    mediaField,
  };
};
