<template>
  <ClientOnly>
    <Teleport to="#app-header">
      <div v-if="show" class="fixed left-0 top-0 bottom-0 right-0 bg-black/30">
        <div class="flex h-full">
          <div class="hidden md:block w-230px"></div>
          <div class="flex-grow flex-align gap-2 bg-black">
            <div class="page-container">
              <div class="flex-align gap-2">
                <div class="text-3.5 font-bold flex-align">
                  <Icon name="clarity:warning-line" size="20" />
                  <span class="ml-1">{{ unsavedText }}</span>
                </div>
                <div class="flex-grow"></div>
                <v-btn
                  @click="discard"
                  :disabled="!canDiscard"
                  color="white"
                  variant="tonal"
                >
                  Discard
                </v-btn>
                <v-btn
                  @click="save"
                  :disabled="!canSave"
                  :loading="saving"
                  color="white"
                >
                  {{ saveText }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppContextualSaveBar",
  setup() {
    const {
      saveText,
      unsavedText,
      isDirty,
      canDiscard,
      canSave,
      fetching,
      persistent,
      loading,
      saving,
      discard,
      save,
    } = useSaveContext();

    const show = computed(
      () => !fetching.value && (persistent.value || isDirty.value)
    );

    return {
      show,
      saveText,
      unsavedText,
      canDiscard,
      canSave,
      loading,
      saving,
      discard,
      save,
    };
  },
});
</script>
