import { get } from "lodash";
import type { MediaFileType, UploadFile } from "../models";
import type { MediaFile } from "../modules/media-library/runtime/types";

// This function is used to detect the actual image type,
export const getMimeType = (
  file: string | ArrayBuffer | null | undefined,
  fallback?: string
) => {
  const byteArray = new Uint8Array(file as any).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
};

export const blobToFile = (blob: Blob, name: string): File => {
  return new File([blob], name, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
};

export const getFileSrc = (
  file: UploadFile,
  media?: MediaFile
): Promise<MediaFile> => {
  const name = get(file, "name") || media?.name || "";
  const f = file instanceof Blob ? blobToFile(file, name) : file;

  const src = URL.createObjectURL(f);

  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const mimetype = getMimeType(e.target?.result, f.type) || "";
      const [type] = mimetype.split("/");

      resolve({
        id: "",
        name,
        src,
        image: src,
        type: type as MediaFileType,
      });
    };
    reader.readAsArrayBuffer(f);
  });
};
