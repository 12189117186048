import { isEmpty, isObject, get } from "lodash";
import { cloneDeep } from "@apollo/client/utilities";
import type { Dobby } from "@moirei/dobby";
import { Media, type Model } from "../models";
import { getMediaFileUpload } from "../private/media-input/lib";

export type SaveContextCustomFill = {
  data: any;
  original: any;
  isDirty: boolean;
};

/**
 * Fill model with save input options.
 * Adds new, updates, or marks array relations for deletes where possible.
 */
export const fillModel = (
  input: {
    changes: any;
    data: any;
    original: any;
  },
  model: Model
) => {
  model.$fill(input.changes);

  const shouldSetUpload = (m: Dobby, data: any) => {
    const upload = getMediaFileUpload(data);
    if (upload && m instanceof Media) {
      m.$setUpload(upload);
    }
  };

  const shouldMarkForDeletes = (m?: Dobby | Model) => {
    if (m?.$markForDeletes) {
      m.$markForDeletes();
    }
  };

  for (const attr in input.changes) {
    if (
      !isEmpty(input.changes[attr]) && // has changes
      isObject(input.data[attr]) && // current state (original or changes) is an object
      model.$self().getAttributeField(attr) // attr is a field on model
    ) {
      // if the attribute is an object (json, mixed, etc)
      // set the actual value (not diff changes)
      model.$setAttribute(attr, input.data[attr]);
      continue;
    }

    const field = model.$self().getRelationshipField(attr);

    if (field && isObject(input.changes[attr])) {
      if (field.isList) {
        const relation = model[attr] as Model[];
        const newValues = cloneDeep(input.data[attr]) as any[]; // clone so that changes does not affect original
        const oldValues = cloneDeep(input.original[attr]) as any[];

        const takeNew = (i: number) => {
          const nv = newValues[i];
          deleteAt(newValues, i);
          return nv;
        };

        const takeOld = (i: number) => {
          const nv = oldValues[i];
          deleteAt(oldValues, i);
          return nv;
        };

        relation.forEach((m) => {
          const key = m.$getKey();
          const primaryKey = m.$getKeyName();
          const i = newValues.findIndex((v) => get(v, primaryKey) == key);
          if (i >= 0) {
            const nv = takeNew(i);
            const original = m.$toJson();
            const changes = deepDiff<any>(nv, original);
            fillModel({ original, data: nv, changes }, m);
          } else {
            const i = oldValues?.findIndex((v) => get(v, primaryKey) == key);
            if (i >= 0) {
              // model exists in old values.
              //  this means it has been removed. Mark for deletes if possible.
              takeOld(i);
              shouldMarkForDeletes(m);
            }
          }
        });

        // remaining new values do not exist
        newValues.forEach((nv) => {
          const m = field.model.make() as Model;
          fillModel({ original: {}, data: nv, changes: nv }, m);
          shouldSetUpload(m, nv);
          relation.push(m);
        });
      } else {
        let m = model[attr] as Dobby;
        if (!input.data[attr] && input.original[attr]) {
          // I don't think we ever get here but just in case
          shouldMarkForDeletes(m);
        } else {
          if (m) {
            m.$fill(input.changes[attr]);
          } else {
            model.$attach(attr, input.data[attr]);
            m = model[attr] as Dobby;
          }
          shouldSetUpload(m, input.data[attr]);
        }
      }
    } else if (field && isObject(input.original[attr])) {
      if (field.isList) {
        const relation = model[attr] as Model[];
        relation.forEach((m) => {
          shouldMarkForDeletes(m);
        });
      } else {
        const m = model[attr];
        shouldMarkForDeletes(m);
      }
    } else {
      const data = input.data[attr];
      const original = input.original[attr];
      let custom: SaveContextCustomFill;
      if (isObject(data) || isObject(original)) {
        const changes = deepDiff<any>(data || {}, original || {});
        const isDirty = !isEmpty(changes);
        custom = { data, original, isDirty };
      } else {
        custom = { data, original, isDirty: data !== original };
      }
      model.$setSaveContextAttribute(attr, custom);
    }
  }
};
