import type { MediaFile } from "../../modules/media-library/runtime/types";

export type MediaCropperData = {
  id: string;
  aspectRatio: number;
  media: MediaFile;
  done: (file: Blob) => void;
  cancel: () => void;
};

const store = ref<MediaCropperData[]>([]);

export const useCropStore = () => {
  const add = (media: MediaFile, aspectRatio: number) => {
    return new Promise<Blob>((resolve, reject) => {
      const id = uuid();
      const rmFn = () => setTimeout(() => remove(id), 1000);
      const done = (file: Blob) => {
        resolve(file);
        rmFn();
      };
      const cancel = () => {
        reject();
        rmFn();
      };

      store.value.push({
        id,
        aspectRatio,
        media,
        done,
        cancel,
      });
    });
  };

  const remove = (id: string) => {
    store.value = store.value.filter((o) => o.id !== id);
  };

  return {
    entries: readonly(store),
    add,
  };
};

export const useCropper = () => {
  const { add } = useCropStore();
  return add;
};
