import { get, set } from "lodash";
import type { MediaFile } from "../../modules/media-library/runtime/types";
import type { UploadFile } from "../../models";

export const setMediaFileId = (media: MediaFile, id: string) => {
  set(media, "$id", id);
};

export const getMediaFileId = (media: MediaFile) => {
  return get(media, "$id") || media.id;
};

export const setMediaFileUpload = (media: MediaFile, upload: UploadFile) => {
  set(media, "$upload", upload);
};

export const getMediaFileUpload = (
  media: MediaFile
): UploadFile | undefined => {
  return get(media, "$upload");
};
